.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1A1A1A;
  z-index: 1000;
  opacity: 0.5;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  z-index: 1000;
  box-sizing: border-box;
  box-shadow: 0 0 5px #1A1A1A;
  font-family: 'ProximaNova';
}
.modal .modal-header {
  background-color: #003359;
  color: #FFFFFF;
  padding: 27px;
  border: none;
  text-align: center;
  min-height: 16.43px;
}
.modal .modal-header .modal-title {
  font-family: 'ProximaNova';
  margin: 0;
  color: #FFFFFF;
  line-height: 1.428571429;
  text-transform: uppercase;
  font-size: 24px;
}
.modal .modal-header .close-modal {
  font-size: 1.9em;
  color: #FFFFFF;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 0px;
}
.modal .modal-header .close-modal:focus {
  outline: none;
}
.modal .modal-body {
  padding: 15px;
  position: relative;
}
.modal .modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #E5E5E5;
}
@media (min-width: 768px) {
  .modal {
    width: 600px;
  }
}
@media (min-width: 992px) {
  .modal {
    width: 900px;
  }
}
