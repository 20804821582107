.footer-policies-modal .modal-body {
  max-height: calc(90vh - (72px + 125px));
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;
  padding-top: 0px;
}
.footer-policies-modal .modal-body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.footer-policies-modal .modal-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.footer-policies-modal .modal-body .wse-typo-body {
  font-family: 'ProximaNova';
  text-transform: none;
  font-size: 1em;
  line-height: 1.5em;
  color: #1A1A1A;
}
.footer-policies-modal .modal-body .wse-typo-body.policy-updated-date {
  margin-bottom: 10px;
}
.footer-policies-modal .modal-body .wse-typo-body.summary-title {
  text-align: center;
}
.footer-policies-modal .modal-body .typo-default-font-size {
  font-size: 16px;
}
.footer-policies-modal .modal-body .language-font-my .wse-typo-body {
  font-family: "Pyidaungsu";
}
.footer-policies-modal .modal-body .language-font-vi .wse-typo-body {
  font-family: "Noto Sans";
}
.footer-policies-modal .modal-body .decrease-content-font .wse-typo-body {
  font-size: 14px;
}
.footer-policies-modal .modal-body .alpha-list {
  list-style-type: lower-alpha;
}
.footer-policies-modal .modal-body .privacy-policy-summary-table {
  margin: 10px 0px;
  font-size: 14px;
  border-collapse: collapse;
  border-spacing: 0;
}
.footer-policies-modal .modal-body .privacy-policy-summary-table tr {
  border: 1px solid #808080;
}
.footer-policies-modal .modal-body .privacy-policy-summary-table tr > td:first-child {
  border-right: 1px solid #808080;
}
.footer-policies-modal .modal-body .privacy-policy-summary-table tr td {
  padding: 8px;
  line-height: 24px;
}
