.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 25px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 13px 0px;
  overflow: hidden;
  border: 1px solid #003359;
  background-color: #003359;
}
.footer p {
  color: #fff;
  font-size: 14px;
  margin-right: 3px;
  margin-bottom: 0px;
  float: left;
  margin-top: 0;
  font-family: 'ProximaNova';
}
.footer p.pointer {
  cursor: pointer;
}
