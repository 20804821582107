.wse-logo {
  width: 80px;
}

.enterprise-logo {
  width: 150px;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  height: 65px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid #003359;
  background-color: #003359;
  z-index: 1000;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
}

.nav-menu-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  align-items: center;
}

.nav-menu-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}

