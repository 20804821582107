/**
* 400 is the same as normal/regular
* 600 semi bold
* 700 is the same as bold
* 900 extra bold
*/

@font-face {
  font-family: 'FontAwesome';
  src: url('../assets/fonts/fontawesome-webfont.eot');
  src: url('../assets/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/fontawesome-webfont.woff') format('woff'), url('../assets/fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova-Reg-webfont.eot');
  src: url('../assets/fonts/ProximaNova-Reg-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/ProximaNova-Reg-webfont.woff2') format('woff2'), url('../assets/fonts/ProximaNova-Reg-webfont.woff') format('woff'), url('../assets/fonts/ProximaNova-Reg-webfont.ttf') format('truetype'), url('../assets/fonts/ProximaNova-Reg-webfont.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova-RegIt-webfont.eot');
  src: url('../assets/fonts/ProximaNova-RegIt-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/ProximaNova-RegIt-webfont.woff2') format('woff2'), url('../assets/fonts/ProximaNova-RegIt-webfont.woff') format('woff'), url('../assets/fonts/ProximaNova-RegIt-webfont.ttf') format('truetype'), url('../assets/fonts/ProximaNova-RegIt-webfont.svg') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova-Sbold-webfont.eot');
  src: url('../assets/fonts/ProximaNova-Sbold-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/ProximaNova-Sbold-webfont.woff2') format('woff2'), url('../assets/fonts/ProximaNova-Sbold-webfont.woff') format('woff'), url('../assets/fonts/ProximaNova-Sbold-webfont.ttf') format('truetype'), url('../assets/fonts/ProximaNova-Sbold-webfont.svg') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova-SboldIt-webfont.eot');
  src: url('../assets/fonts/ProximaNova-SboldIt-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/ProximaNova-SboldIt-webfont.woff2') format('woff2'), url('../assets/fonts/ProximaNova-SboldIt-webfont.woff') format('woff'), url('../assets/fonts/ProximaNova-SboldIt-webfont.ttf') format('truetype'), url('../assets/fonts/ProximaNova-SboldIt-webfont.svg') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova-Bold-webfont.eot');
  src: url('../assets/fonts/ProximaNova-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/ProximaNova-Bold-webfont.woff2') format('woff2'), url('../assets/fonts/ProximaNova-Bold-webfont.woff') format('woff'), url('../assets/fonts/ProximaNova-Bold-webfont.ttf') format('truetype'), url('../assets/fonts/ProximaNova-Bold-webfont.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova-BoldIt-webfont.eot');
  src: url('../assets/fonts/ProximaNova-BoldIt-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/ProximaNova-BoldIt-webfont.woff2') format('woff2'), url('../assets/fonts/ProximaNova-BoldIt-webfont.woff') format('woff'), url('../assets/fonts/ProximaNova-BoldIt-webfont.ttf') format('truetype'), url('../assets/fonts/ProximaNova-BoldIt-webfont.svg') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Pyidaungsu";
  src: url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.eot?#iefix') format("embedded-opentype"), /* IE6-IE8 */
    url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.otf') format("opentype"), /* Open Type Font */
    url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.svg') format("svg"), /* Legacy iOS */
    url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.ttf') format("truetype"), /* Safari, Android, iOS */
    url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.woff') format("woff"), /* Modern Browsers */
    url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.woff2') format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/NotoSans/NotoSans-Regular.eot');
  src: url('../assets/fonts/NotoSans/NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/NotoSans/NotoSans-Regular.woff2') format('woff2'),
      url('../assets/fonts/NotoSans/NotoSans-Regular.woff') format('woff'),
      url('../assets/fonts/NotoSans/NotoSans-Regular.ttf') format('truetype'),
      url('../assets/fonts/NotoSans/NotoSans-Regular.svg#NotoSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}